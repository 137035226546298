<template>
  <a
    ref="refBHeaderCart"
    :class="headerCartClass"
    :aria-label="ariaLabel"
    @click="handleClick"
  >
    <CartSvg class="bsc-header-cart__svg" />

    <CartNum :num="cartNum" />

    <CartTag
      v-if="!disabledLure"
      ref="refCartTag"
      :theme-vars="themeVars"
      @click-tip="handleClickTip"
      @expose-tip="handleExposeTip"
    />
  </a>
</template>

<script name="BHeaderCart" setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
import {
  cartTagTip,
  registerCart,
  unregisterCart,
  useCartNum,
} from '@shein-aidc/bs-sdk-cart-tag-tip'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { injectCommonHeaderEventProxy } from '@shein-aidc/bs-common-header-mobile'
import { useIsVisible } from './hooks/useIsVisible'
import CartSvg from './svgs/cart.vue'
import CartNum from './components/cart-num.vue'
import CartTag from './components/cart-tag.vue'

import type { AS_HeaderCart, C_HeaderCart, Placement } from '../../types'
import {useAnalysis} from '../../common/analysisSource'

const emit = defineEmits(['before-click', 'click'])

interface IProps {
  // fsData?: DS_HeaderCart.FS_DATA
  // dataSource?: DS_HeaderCart.DataSource
  analysisSource?: AS_HeaderCart.AnalysisSource
  themeVars?: C_HeaderCart.ThemeVars
  ariaLabel?: string
  placement?: Placement
  offsetY?: number
  loc?: string
  isCustomClick?: boolean
  disabledLure?: boolean
}
const props = withDefaults(defineProps<IProps>(), {
  placement: 'bottom-end',
  offsetY: 10,
  isCustomClick: false,
})

const { triggerNotice } = useAnalysis(props.analysisSource)

const CommonHeaderEventProxyEventProxy = injectCommonHeaderEventProxy()

if (CommonHeaderEventProxyEventProxy) {
  CommonHeaderEventProxyEventProxy.describe('BHeaderCart')
  CommonHeaderEventProxyEventProxy.subscribe('BHeaderCart', {
    pageOnload() {
      onExpose()
    },
  })
}

const appConfigs = useAppConfigs()

const { cartNum } = useCartNum()

const headerCartClass = computed(() => {
  const clas:string[] = ['bsc-header-cart']
  let sizeClassname = 'bsc-header-cart__24px'
  if (props.themeVars?.iconSize && [24, 32].includes(props.themeVars.iconSize)) {
    sizeClassname = `bsc-header-cart__${props.themeVars.iconSize}px`
  }
  clas.push(sizeClassname)
  return clas
})
const ariaLabel = computed(() => props.ariaLabel || appConfigs.$language.BHeaderCart?.Cart || 'Cart')

const handleClick = () => {
  cartTagTip.getExposeData().then(extraData => {
    triggerNotice({
      id: 'click_home_bag.comp_header-cart',
      data: {
        type: '1',
        abtest: '',
        actual_point: extraData.actual_point,
        available_point: extraData.available_point,
        bag_goods_count: cartNum.value,
        loc: props.loc,
      },
    })
  })
  emit('before-click', { type: 'icon' })

  if (props.isCustomClick) {
    emit('click', { type: 'icon' })
    return
  }
  appConfigs.$router.push({ path: `${appConfigs.$envs.langPath}/cart` })
}
const handleClickTip = ({ extraData }) => {
  triggerNotice({
    id: 'click_equity_pop.comp_header-cart',
    data: {
      actual_point: extraData.actual_point_tip,
      available_point: extraData.available_point_tip,
    },
  })
  emit('before-click', { type: 'tip' })

  if (props.isCustomClick) {
    emit('click', { type: 'tip' })
    return
  }
  appConfigs.$router.push({ path: `${appConfigs.$envs.langPath}/cart` })
}
const handleExposeTip = ({ extraData }) => {
  triggerNotice({
    id: 'expose_equity_pop.comp_header-cart',
    data: {
      actual_point: extraData.actual_point_tip,
      available_point: extraData.available_point_tip,
    },
  })
}
const onExpose = () => {
  setTimeout(() => {
    if (!isVisible.value) return
    cartTagTip.getExposeData().then(extraData => {
      triggerNotice({
        id: 'expose_home_bag.comp_header-cart',
        data: {
          actual_point: extraData.actual_point,
          available_point: extraData.available_point,
          bag_goods_count: cartNum.value,
          loc: props.loc,
        },
      })
    })
  }, 300)
}

const refBHeaderCart = ref<HTMLElement | null>(null)
const { isVisible } = useIsVisible(refBHeaderCart)
const cartListener = {
  showtime: (steps, config) => {
    setTag(steps, config)
  },
  endtime: () => {
    clear()
  },
}
watch(
  isVisible,
  v => {
    if (typeof window === 'undefined') return
    if (v) {
      registerCart('header-cart', cartListener)
      refCartTag.value?.init()
    } else {
      unregisterCart('header-cart', cartListener)
    }
  },
  {
    immediate: true,
  },
)
onMounted(() => {
  if (!CommonHeaderEventProxyEventProxy) {
    // 不在 common-header 中使用时，组件内部自己曝光一次
    onExpose()
  }

  // watch isVisible 注册
  // registerCart('header-cart', cartListener)
})
onUnmounted(() => {
  unregisterCart('header-cart', cartListener)
  CommonHeaderEventProxyEventProxy?.describe('BHeaderCart')
})

const refCartTag = ref<typeof CartTag | null>(null)
const setTag = (steps, config) => {
  if (props.disabledLure) {
    config?.callback?.()
    return
  }
  refCartTag.value?.setTag(steps, {
    reference: refBHeaderCart.value,
    placement: props.placement,
    offsetY: props.offsetY,
    ...config,
  })
}
const clear = (...args) => {
  refCartTag.value?.clear(...args)
}
defineExpose({
  setTag,
  clear,
  onExpose,
})
</script>

<style lang="less">
.bsc-header-cart {
  position: relative;
  display: inline-block; // 兼容 app webview a 标签高度为 0 的问题
  line-height: initial;
  font-size: 0;

  &__32px {
    .bsc-header-cart__svg {
      width: 32px;
      height: 32px;
    }

    .bsc-cart-num {
      top: -7px;
    }

    .bsc-cart-tag {
      bottom: -9px;
    }
  }
}
</style>
