<template>
  <transition
    :name="transitionName"
    @after-enter="afterEnter"
  >
    <span
      v-if="type === 'freeshipping'"
      key="freeshipping"
      class="bsc-cart-tag bsc-cart-tag--freeshipping"
      :style="bgStyle"
    >
      <FreeshippingSvg />
      <span class="bsc-cart-tag__text">Free</span>
    </span>
    <span
      v-else-if="type === 'save'"
      key="save"
      :class="[
        'bsc-cart-tag',
        'bsc-cart-tag--save',
        {
          'no-padding': text !== 'SAVE',
        },
      ]"
      :style="bgStyle"
    >
      <template v-if="!textStyle && text === 'SAVE'">
        <SaveSvg />
      </template>
      <span
        class="bsc-cart-tag__text"
        :style="textStyle"
      >
        {{ text }}
      </span>
    </span>
    <span
      v-else-if="type === 'gift'"
      key="gift"
      class="bsc-cart-tag bsc-cart-tag--gift"
      :style="bgStyle"
    >
      <template v-if="!textStyle">
        <GiftSvg />
      </template>
      <span
        class="bsc-cart-tag__text"
        :style="textStyle"
      >
        GIFT
      </span>
    </span>
  </transition>

  <ClientOnly>
    <Teleport to="body">
      <CartTip
        v-if="cartTipShow"
        ref="refCartTip"
        @after-enter="cartTipAfterEnter"
        @click="handleCartTipClick"
        @expose="handleCartTipExpose"
      />
    </Teleport>
  </ClientOnly>
</template>

<script name="BCartTag" setup lang="ts">
import { ref, computed, onMounted, nextTick } from 'vue'
import { cartTagTip } from '@shein-aidc/bs-sdk-cart-tag-tip'
import type { TagTip } from '@shein-aidc/bs-sdk-cart-tag-tip'
import FreeshippingSvg from '../svgs/freeshipping.vue'
import SaveSvg from '../svgs/save.vue'
import GiftSvg from '../svgs/gift.vue'
import CartTip from './cart-tip.vue'
import type { C_HeaderCart } from '../../../types'
import { ClientOnly } from '@sheinfe/vue-client-only'

const emit = defineEmits(['click-tip', 'expose-tip'])

const props = defineProps<{
  themeVars?: C_HeaderCart.ThemeVars
}>()

const useCartTip = () => {
  const refCartTip = ref(null)
  const show = ref(false)
  const open = (...args) => {
    show.value = true
    nextTick(() => {
      refCartTip.value.open(...args)
    })
  }
  const clear = () => {
    refCartTip.value?.clear()
  }
  const afterEnter = () => {
    show.value = false
  }
  return {
    refCartTip,
    show,
    open,
    clear,
    afterEnter,
  }
}
const { refCartTip, show: cartTipShow, open: cartTipOpen, clear: cartTipClear, afterEnter: cartTipAfterEnter } = useCartTip()
const handleCartTipClick = () => {
  cartTagTip.getExposeData().then(extraData => {
    emit('click-tip', { extraData })
  })
}
const handleCartTipExpose = () => {
  cartTagTip.getExposeData().then(extraData => {
    emit('expose-tip', { extraData })
  })
}

const bgStyle = computed(() => {
  if (props.themeVars?.tagTipBgColor) {
    return {
      '--tag-tip-bg-color': props.themeVars.tagTipBgColor,
    }
  }
  return undefined
})
const textStyle = computed(() => {
  if (props.themeVars?.tagTipTextColor) {
    return {
      '--tag-tip-text-color': props.themeVars.tagTipTextColor,
    }
  }
  return undefined
})

const transitionName = ref('')
const type = ref('')
const text = ref<boolean | string | null>('')

let excludeScroll = false
let nextStep: TagTip.Step | null = null
let timer: ReturnType<typeof setTimeout> | null = null
let callback: TagTip.Config['callback']

const setTag = async (steps: TagTip.Step[], config: TagTip.Config) => {
  const [step1 = null, step2 = null] = steps
  // TODO: step1.excludeScroll 放在 config 中
  if (step1?.excludeScroll) await sleep(300)
  excludeScroll = false

  clear()

  nextTick(() => {
    if (step1) {
      excludeScroll = step1.excludeScroll || false
      if (!step1.type) {
        callback = config.callback
        type.value = ''
        text.value = ''
        transitionName.value = ''

        // 没有动画 手动执行 afterEnter
        afterEnter()
      } else {
        if (step1.noTips || !step1.tips) {
          callback = config.callback
          // 没有动画 手动执行 afterEnter
          if (type.value === step1.type) afterEnter()
        } else {
          nextTick(() => {
            const cfg: C_HeaderCart.TipOpenConfig = {
              bgStyle: bgStyle.value,
              textStyle: textStyle.value,
              reference: config.reference,
              placement: config.placement,
              offsetY: config.offsetY,
            }
            if (!step2) cfg.callback = config.callback

            cartTipOpen(step1, cfg)
          })
        }
        type.value = step1.type
        text.value = step1.tag
        nextStep = null
        transitionName.value = 'bsc-header-cart__fade'
      }
    }

    if (step2) {
      nextStep = step2
      timer = setTimeout(() => {
        callback = config.callback
        type.value = step2.type
        text.value = step2.tag
        transitionName.value = 'bsc-header-cart__zoom'
      }, 3000)
    }
  })
}

const afterEnter = () => {
  callback?.()
  callback = undefined
}

// 打断动画
const clear = () => {
  if (excludeScroll) return
  transitionName.value = ''

  if (nextStep) {
    type.value = nextStep.type
    text.value = nextStep.tag
    nextStep = null
    clearTimeout()
  }

  cartTipClear()

  // 动画被打断不会执行 afterEnter
  afterEnter()
}

const clearTimeout = () => {
  if (timer) {
    window.clearTimeout(timer)
    timer = null
  }
}

const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const init = async () => {
  const data = await cartTagTip.getCurrentTag()
  type.value = data.type
  text.value = data.tag
}

onMounted(() => {
  init()

  // console.log('%c refHeaderCartTip', 'font-size:20px', refHeaderCartTip)
  // window.x = refHeaderCartTip

  // refHeaderCartTip.$on('expose', () => {
  //   cartTagTip.getExposeData().then(extraData => {
  //     emit('expose-tip', { extraData })
  //   })
  // })
  // refHeaderCartTip.$on('click', () => {
  //   cartTagTip.getExposeData().then(extraData => {
  //     emit('click-tip', { extraData })
  //   })
  // })
})

defineExpose({
  init,
  setTag,
  clear,
})
</script>

<style lang="less">
@keyframes bsc-header-cart__fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bsc-header-cart__zoom {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
    transform: translateX(-50%) scaleX(0.5);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) scaleX(1);
  }
}

.bsc-header-cart__zoom-enter-active {
  animation: bsc-header-cart__zoom 0.3s;
}

.bsc-header-cart__zoom-leave-active {
  animation: bsc-header-cart__zoom 0.3s reverse;
}

.bsc-header-cart__fade-enter-active {
  animation: bsc-header-cart__fade 0.3s;
}

.bsc-header-cart__fade-leave-active {
  animation: bsc-header-cart__fade 0.3s reverse;
}

.bsc-cart-tag {
  position: absolute;
  left: 50%;
  bottom: -10px;
  display: inline-flex;
  align-items: center;
  padding: 0 2px;
  height: 14px;
  font-size: 12px;
  white-space: nowrap;
  border-radius: 8px;
  transform: translateX(-50%);

  &.no-padding {
    padding: 0;
  }

  &--freeshipping,
  &--gift,
  &--save {
    color: #fff;
  }

  &--freeshipping {
    padding: 0 2px 0 4px;
    background: #198055;
  }

  &--gift {
    padding: 0 2px 0 4px;
  }

  &--save,
  &--gift {
    background-color: #fa6338;
    background-color: var(--tag-tip-bg-color, #fa6338);
  }

  &__text {
    margin: 0 -2px;
    transform: scale(0.75);
    color: var(--tag-tip-text-color, #fff);
  }
}
</style>
