<template>
  <span
    v-show="formatNum"
    class="bsc-cart-num"
    data-allow-mismatch
  >
    {{ formatNum }}
  </span>
</template>

<script name="BCartNum" setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  num: number
}>()

const formatNum = computed(() => {
  if (props.num > 99) {
    return '99+'
  }
  return props.num
})
</script>

<style lang="less">
.bsc-cart-num {
  position: absolute;
  top: -9px;
  right: -7.5px /* rtl: auto */;
  left: auto /* rtl: -7.5px */;
  min-width: 15px;
  height: 15px;
  padding: 3.75px;
  box-sizing: border-box;
  font-size: 10px;
  display: block;
  border-radius: 15px;
  text-align: center;
  line-height: 5.5px;
  background: #fe3b30;
  color: #fff;
  border: 1px solid var(--shein-common-header-badge-border-color, transparent);
}
</style>
