<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.16068 3.33313L8.33708 2.25969L7.66304 1.521L6.00506 3.03387L4.39965 1.52585L3.715 2.25472L4.86306 3.33313H1.16675L1.16681 6.49976H1.83341V10.9998H10.1667V6.49976H10.8335L10.8334 3.33313H7.16068ZM9.16675 6.49976H2.83341V9.99976H9.16675V6.49976ZM9.83348 5.49976L9.83342 4.33313H2.16675L2.16681 5.49976H9.83348Z"
      fill="white"
    />
  </svg>
</template>
